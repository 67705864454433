<template>
  <section class="bg-white p-4 mb-5 rounded-sm">
    <h5 class="font-weight-bold mb-3">
      {{ $t('sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.title1') }}
    </h5>
    <template v-if="step == 1">
      <p class="font-size-sm">
        {{
          $t(
            'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.paragraph.pt1'
          )
        }}
        <strong>
          {{
            $t(
              'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.paragraph.pt2'
            )
          }}
          <template v-if="selectedScheduleRelease === 'not_schedule'">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.paragraph.released-without-requirement'
              )
            }}
          </template>
          <template v-else>
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.paragraph.scheduled-release'
              )
            }}
          </template>
        </strong>
      </p>

      <MXButton class="mt-3" variant="secondary" @click="onChangeRelease" data-testid="change-lesson-release-button">{{
        $t(
          'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.buttons.change-navigation'
        )
      }}</MXButton>
    </template>
    <template v-if="step == 2">
      <hs-form @submit="onSave">
        <div class="mb-3">
          <RadioInputBox :hide-box="true" v-model="selectedScheduleRelease" :options="['release_after', 'release_at']">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.release.radio.keep-all-times'
              )
            }}
          </RadioInputBox>

          <RadioInputBox :hide-box="true" v-model="selectedScheduleRelease" value="release_after">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.release.radio.release-text'
              )
            }}
            <hs-input
              type="number"
              v-model="schedule.release_after"
              :disabled="selectedScheduleRelease !== 'release_after'"
            />
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.release.radio.days-after-enrolment'
              )
            }}
          </RadioInputBox>

          <RadioInputBox :hide-box="true" v-model="selectedScheduleRelease" value="release_at">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.release.radio.release-on'
              )
            }}
            <hs-date
              v-model="releaseDate"
              :label-no-date-selected="$t('date.format')"
              size="sm"
              :disabled="selectedScheduleRelease !== 'release_at'"
              :min="new Date()"
              value-as-date
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </RadioInputBox>
        </div>

        <div class="border-top">
          <h5 class="font-weight-bold my-3">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.title2'
              )
            }}
          </h5>

          <RadioInputBox :hide-box="true" name="lock" v-model="selectedScheduleUnlock" value="unlocked">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.limited.radio.unlocked'
              )
            }}
          </RadioInputBox>

          <RadioInputBox :hide-box="true" name="lock" v-model="selectedScheduleUnlock" value="lock_after">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.limited.radio.limited-text'
              )
            }}
            <hs-input type="number" v-model="schedule.lock_after" :disabled="selectedScheduleUnlock !== 'lock_after'" />
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.limited.radio.days-after-enrolment'
              )
            }}
          </RadioInputBox>

          <RadioInputBox :hide-box="true" name="lock" v-model="selectedScheduleUnlock" value="lock_at">
            {{
              $t(
                'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.limited.radio.limited-on'
              )
            }}
            <hs-date
              v-model="lockDate"
              :label-no-date-selected="$t('date.format')"
              size="sm"
              :disabled="selectedScheduleUnlock !== 'lock_at'"
              :min="new Date()"
              value-as-date
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </RadioInputBox>
        </div>

        <MXButton class="mt-3" variant="secondary" @click="onSave" type="submit">{{
          $t(
            'sparkmembers.contents.views.editor.components.lesson.components.lesson-release-limitation-card.buttons.save'
          )
        }}</MXButton>
      </hs-form>
    </template>
    <hsFunnelsPremiumModal id="premium-modal-id" @click="redirectToSalesPage" />
  </section>
</template>

<script>
import dayjs from 'dayjs';
import { hsForm } from '@/components';
import hsFunnelsPremiumModal from '@/sparkfunnels/components/FunnelsPremiumModal';
import TrackingHelper from '@/shared/helpers/tracking';
import { LocationSegmentProps, PaywallReasonSegmentProps } from '@/libs/paywall';
import MXButton from '@/shared/components/MXButton.vue';
import RadioInputBox from '@/shared/components/RadioInputBox.vue';

export default {
  name: 'LessonReleaseLimitationCard',
  components: {
    hsFunnelsPremiumModal,
    hsForm,
    MXButton,
    RadioInputBox,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedScheduleRelease: 'not_schedule',
      selectedScheduleUnlock: 'unlocked',
      step: 1,
      schedule: this.value,
    };
  },
  watch: {
    selectedScheduleRelease(_, oldValue) {
      if (oldValue !== 'not_schedule') this.schedule[oldValue] = null;
    },
    selectedScheduleUnlock(_, oldValue) {
      if (oldValue !== 'unlocked') this.schedule[oldValue] = null;
    },
  },
  computed: {
    releaseDate: {
      get() {
        return this.schedule.release_at ? dayjs(this.schedule.release_at).toDate() : null;
      },
      set(newDate) {
        this.schedule.release_at = newDate.toISOString();
      },
    },
    lockDate: {
      get() {
        return this.schedule.lock_at ? dayjs(this.schedule.lock_at).toDate() : null;
      },
      set(newDate) {
        this.schedule.lock_at = newDate.toISOString();
      },
    },
  },
  methods: {
    onChangeRelease() {
      if (!this.$FCL.canUseReleaseLimitation()) {
        this.trackingClassRelease();
        this.$bvModal.show('premium-modal-id');
        return;
      }
      for (const [key, value] of Object.entries(this.schedule)) {
        if (key.includes('release') && value !== null) {
          this.selectedScheduleRelease = key;
        }
        if (key.includes('lock') && value !== null) {
          this.selectedScheduleUnlock = key;
        }
      }

      this.step++;
    },
    trackingClassRelease() {
      TrackingHelper.trackPaywallDisplayed(
        LocationSegmentProps.CLASS_RELEASE,
        PaywallReasonSegmentProps.CLASS_RELEASE,
        this.email,
        this.id,
        this.id
      );
    },
    async onSave() {
      this.$emit('input', this.schedule);
      this.step = 1;
    },
    redirectToSalesPage() {
      TrackingHelper.trackPaywallDisplayed('Product - Edit Lesson', 'lesson_release_limitation');
      this.$router.push({ name: 'MyAccountPlans' });
      this.$bvModal.hide('premium-modal-id');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/mixins/_custom_button.scss';

/deep/ .hs-input {
  display: inline-block;
  width: 55px;
  height: 15px;
  margin: 0 px;

  input[type='number'] {
    height: 27px;
  }
}

/deep/ .custom-radio:last-child {
  .custom-control-label {
    display: flex;
    flex-wrap: wrap;
  }
}

/deep/ .b-form-datepicker {
  width: auto;
}
</style>
