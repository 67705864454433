import Lesson from './LessonReleaseLimitationCard.vue';
import { createLocalVue, shallowMount } from '@vue/test-utils';
import Vuelidate from 'vuelidate';
import TrackingHelper from '@/shared/helpers/tracking';

jest.mock('@/services');
jest.mock('@/shared/helpers/toast');
jest.mock('@/store');
jest.mock('@/shared/helpers/tracking');

const valueMock = {
  lock_after: null,
  lock_at: null,
  release_after: null,
  release_at: null,
};

describe('Lesson', () => {
  const localVue = createLocalVue();
  localVue.directive('b-modal', {});
  localVue.use(Vuelidate);

  let wrapper;

  beforeEach(() => {
    wrapper = mountWrapper(localVue);
  });

  describe('validating permission', () => {
    it('should show premium modal and block access if doesnt have permission', () => {
      wrapper.find('[data-testid="change-lesson-release-button"]').vm.$emit('click');
      expect(wrapper.vm.$bvModal.show).toHaveBeenCalled();
      expect(wrapper.find('HsFunnelsPremiumModal-stub').exists()).toBeTruthy();
    });

    it('should show form if has permission', () => {
      wrapper.vm.$FCL.canUseReleaseLimitation.mockReturnValue(true);
      wrapper.find('[data-testid="change-lesson-release-button"]').vm.$emit('click');
      expect(wrapper.find('HsForm-stub').exists()).toBeTruthy();
    });
  });

  describe('on Save', () => {
    it('should emit input event', async () => {
      expect.hasAssertions();
      const schedule = {
        lock_after: '5',
        lock_at: null,
        release_after: '3',
        release_at: null,
      };
      wrapper.setData({
        schedule: schedule,
      });
      await wrapper.vm.onSave();
      expect(wrapper.emitted().input).toBeDefined();
      expect(wrapper.emitted().input[0][0]).toStrictEqual(schedule);
    });
  });

  describe('redirect to sales page', () => {
    it('should close HsPremiumModal and redirect MyAcconuntPlans', () => {
      wrapper.vm.redirectToSalesPage();

      expect(TrackingHelper.trackPaywallDisplayed).toHaveBeenCalledWith(
        'Product - Edit Lesson',
        'lesson_release_limitation'
      );
      expect(wrapper.vm.$router.push).toHaveBeenCalledWith({ name: 'MyAccountPlans' });
      expect(wrapper.vm.$bvModal.hide).toHaveBeenCalled();
    });
  });
});

function mountWrapper(localVue) {
  return shallowMount(Lesson, {
    localVue,
    propsData: {
      value: valueMock,
      id: 123,
      email: 'teste@herospark.com',
    },
    stubs: {
      HsButton: true,
      HsRadio: true,
      HsInput: true,
      HsDate: true,
      HsFunnelsPremiumModal: true,
      HsForm: true,
    },
    mocks: {
      $t: jest.fn().mockImplementation(x => x),
      $route: {
        params: {
          contentId: 10,
        },
      },
      $router: {
        push: jest.fn(),
      },
      $bvModal: {
        show: jest.fn(),
        hide: jest.fn(),
      },
      $FCL: {
        canUseReleaseLimitation: jest.fn().mockReturnValue(false),
      },
    },
  });
}
